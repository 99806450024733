import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikHelpers } from 'formik';
import moment from 'moment';
import TransactionReportPage from './TransactionReportPage';
import { useFeedback, useTitleBar } from 'providers';
import { useRequest } from 'data/api';
import {
	getAllCards, getCardUsers, getOrganizations, getTransactionStatuses,
} from 'data/api/requests';
import {
	ICard,
	ICardUser,
	ITransactionReport,
	TransactionReportReq,
	TransactionReportReqErrors,
	IOrganization,
	INameAndId,
} from 'data/models';
import { useEffectCustom } from 'components/hooks';
import { formatCurrency } from 'utils/helpers';
import { getTransactionReport } from 'data/api/requests/transactions.service';
import { exportUserExcel } from 'utils/export-xlsx.utils';

const FIRST_DAY_OF_MONTH = new Date(new Date().setDate(1));

const TransactionReport = () => {
	const { setTitle } = useTitleBar();
	const { addDialog } = useFeedback();
	const { t } = useTranslation('translations');
	const abortCtrl = new AbortController();
	const listDataControl = {
		page: 1,
		size: 10000,
		search: '',
	};

	const cards = useRequest(([param]) => getAllCards(param, abortCtrl.signal));
	const cardUsers = useRequest((
		[param1, param2],
	) => getCardUsers(param1, param2, abortCtrl.signal));
	const organizationsRequest = useRequest((
		[params, signal, sort],
	) => getOrganizations(params, signal, sort));
	const statuses = useRequest(() => getTransactionStatuses());

	const [transactions, setTransactions] = useState<ITransactionReport[]>([]);
	const [total, setTotal] = useState(0);
	const [totalDiscount, setTotalDiscount] = useState(0);
	const [initialValues] = useState<TransactionReportReq>({
		startDate: FIRST_DAY_OF_MONTH,
		endDate: new Date(),
		cardId: {} as ICard,
		userId: {} as ICardUser,
		organizationId: {} as IOrganization,
		statusId: {} as INameAndId,
	});

	useEffect(() => {
		setTitle(`${t('reports')} - ${t('transactions')}`);
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			cards.execute(listDataControl);
			organizationsRequest.execute(listDataControl, abortCtrl.signal, true);
			statuses.execute();
		};

		getData();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	const getCardUsersById = (cardId: number) => {
		cardUsers.execute(cardId, listDataControl);
	};

	const validateTransactionForm = (values: TransactionReportReq) => {
		const errors: FormikErrors<TransactionReportReqErrors> = {};
		if (!values.cardId) {
			errors.cardId = t('emptyField');
		}
		if (values.cardId && Object.keys(values.cardId).length === 0) {
			errors.cardId = t('emptyField');
		}
		return errors;
	};

	const filterTransactions = (
		values: TransactionReportReq,
		formikHelpers: FormikHelpers<TransactionReportReq>,
	) => {
		getTransactionReport(values).then((res) => {
			setTransactions(res.data);
			const totalValue = res.data?.reduce((tot, element) => {
				let mTot = tot;
				mTot += element.amount;
				return mTot;
			}, 0 as number);
			setTotal(totalValue);
			const totalDis = res.data?.reduce((dis, element) => {
				let mTotDis = dis;
				mTotDis += element.discount;
				return mTotDis;
			}, 0 as number);
			setTotalDiscount(totalDis);
		}).catch(({ response }) => addDialog({
			title: response.data.title,
			message: response.data.message,
			error: true,
		})).finally(() => formikHelpers.setSubmitting(false));
	};

	const handleExportClick = () => {
		if (transactions.length > 0) {
			const header = ['ID', t('date'), t('description'), t('user'), t('value'), t('discount'), t('status')];
			const coupons = transactions.map((row: ITransactionReport) => ({
				id: row.id,
				date: row.entryDate,
				description: row.description,
				user: `${row.userName} / ${row.userPhone}`,
				value: formatCurrency(row.amount),
				discount: formatCurrency(row.discount),
				status: row.status.name,
			}));
			exportUserExcel(`${t('card')}-${moment(new Date()).format('DD-MM-YYYY HH:mm')}.xlsx`, coupons, header);
		}
	};

	return (
		<TransactionReportPage
			initialValues={initialValues}
			cards={cards.data?.data}
			cardUsers={cardUsers.data?.data}
			getCardUsersById={getCardUsersById}
			validateTransactionReport={validateTransactionForm}
			getTransactions={filterTransactions}
			transactions={transactions}
			total={total}
			totalDiscount={totalDiscount}
			onExportClick={handleExportClick}
			organizations={organizationsRequest.data?.data}
			statuses={statuses.data}
		/>
	);
};

export default TransactionReport;
