import {
	Box,
	Grid,
	Checkbox,
	useTheme,
	FormControlLabel,
	Card,
	RadioGroup,
	Radio,
} from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import RichTextEditor from 'react-rte';
import { ArrowBack, Edit } from '@mui/icons-material';
import { ICreateCouponReq, IOrganization } from 'data/models';
import {
	Autocomplete,
	DatePicker,
	DefaultButton, DefaultSelect, Description, TextField,
} from 'components/atoms';
import { PaperContainer } from 'components/molecules';
import { currency, percentage } from 'utils/masks';
import { logo } from 'assets/image';

export interface CreateCouponPageProps {
   initialValues: ICreateCouponReq;
   organizations?: IOrganization[];
   createCoupons:
   (values: ICreateCouponReq, formikHelpers: FormikHelpers<ICreateCouponReq>) => void;
   loading: boolean;
   validate: (values: ICreateCouponReq) => FormikErrors<ICreateCouponReq>;
   isEdit: boolean;
   handleEditClick: () => void;
   handleBackClick: () => void
}

const CreateCouponPage = (props: CreateCouponPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		organizations,
		initialValues,
		validate,
		createCoupons,
		isEdit,
		handleEditClick,
		handleBackClick,
	} = props;
	const banner = useRef<HTMLInputElement | null>(null);

	return (
		<Box>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('back')} startIcon={<ArrowBack />} onClick={handleBackClick} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							validateOnMount={false}
							validateOnBlur={false}
							validateOnChange={false}
							enableReinitialize
							validate={validate}
							onSubmit={createCoupons}
						>
							{({
								values, errors, handleChange, setFieldValue, isSubmitting, touched,
							}) => (
								<Form autoComplete="off">
									<input
										type="file"
										id="image"
										ref={banner}
										disabled={!isEdit}
										onChange={(event) => {
											if (event?.target?.files) {
												if (event?.target?.files.length > 0) 												{
													setFieldValue('file', event.target?.files[0]);
													setFieldValue('banner', URL.createObjectURL(event.target?.files[0]));
												}
											}
										}}
										style={{ display: 'none' }}
									/>
									<Grid container spacing={3}>
										<Grid item xs={12} md={4}>
											<TextField
												name="title"
												label={t('title')}
												type="text"
												inputProps={{ maxLength: 45 }}
												disabled={isSubmitting || !isEdit}
												value={values.title}
												onChange={handleChange}
												error={touched.title && Boolean(errors.title)}
												helperText={touched.title && errors.title}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<Autocomplete
												label={t('participatingOrganizations')}
												placeholder=""
												multiple
												disabled={isSubmitting || !isEdit}
												options={organizations || []}
												// eslint-disable-next-line no-confusing-arrow
												getOptionLabel={(item) => typeof item !== 'string' ? item.fantasyName : ''}
												renderOption={(inputprops, item) => (
													<li {...inputprops}>{item.fantasyName}</li>)}
												defaultValue={values.organizations}
												isOptionEqualToValue={(item, option) => item.id === option.id}
												onChange={(event, items) => {
													if (items) {
														setFieldValue('organizations', items);
													}
												}}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<TextField
												name="maxUsage"
												id="maxUsage"
												label={t('quantity')}
												value={values.maxUsage}
												disabled={isSubmitting || !isEdit}
												error={touched.maxUsage && Boolean(errors.maxUsage)}
												helperText={touched.maxUsage && errors.maxUsage}
												type="number"
												size="small"
												fullWidth
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<TextField
												value={values.cost}
												error={touched.cost && Boolean(errors.cost)}
												helperText={touched.cost && errors.cost}
												disabled={isSubmitting || !isEdit}
												label={t('cost')}
												id="cost"
												type="string"
												onChange={(event) => setFieldValue('cost', currency(event).target.value)}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<TextField
												value={values.description}
												name="description"
												error={touched.description && Boolean(errors.description)}
												helperText={touched.description && errors.description}
												id="description"
												label={t('description')}
												disabled={isSubmitting || !isEdit}
												type="text"
												size="small"
												fullWidth
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<TextField
												value={values.productExternalId}
												name="productExternalId"
												error={touched.productExternalId && Boolean(errors.productExternalId)}
												helperText={touched.productExternalId && errors.productExternalId}
												id="productExternalId"
												label={t('externalId')}
												disabled={isSubmitting || !isEdit}
												type="text"
												size="small"
												fullWidth
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<TextField
												value={values.minPurchaseAmount}
												error={touched.minPurchaseAmount && Boolean(errors.minPurchaseAmount)}
												helperText={touched.minPurchaseAmount && errors.minPurchaseAmount}
												label={t('minValue')}
												disabled={isSubmitting || !isEdit}
												id="minPurchaseAmount"
												type="text"
												onChange={(event) => setFieldValue('minPurchaseAmount', currency(event).target.value)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<TextField
												value={values.maxPurchaseAmount}
												error={touched.maxPurchaseAmount && Boolean(errors.maxPurchaseAmount)}
												helperText={touched.maxPurchaseAmount && errors.maxPurchaseAmount}
												label={t('maxValue')}
												id="maxPurchaseAmount"
												disabled={isSubmitting || !isEdit}
												type="text"
												onChange={(event) => setFieldValue('maxPurchaseAmount', currency(event).target.value)}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<DefaultSelect
												options={[{ label: t('discount'), value: 1 }, { label: t('reimbursment'), value: 2 }] || []}
												label={t('category')}
												value={values.categoryId}
												disabled={isSubmitting || !isEdit}
												renderItem={(item) => ({
													optionLabel: item.label, optionValue: item.value,
												})}
												onChange={(event) => {
													setFieldValue('categoryId', event.target.value);
												}}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<DatePicker
												label={t('startDate')}
												value={values.startDate}
												error={!!errors.startDate}
												helperText={String(errors.startDate || '')}
												disabled={isSubmitting || !isEdit}
												onChange={(value) => setFieldValue('startDate', value)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<DatePicker
												label={t('endDate')}
												minDate={values.startDate}
												error={!!errors.endDate}
												helperText={String(errors.endDate || '')}
												value={values.endDate}
												disabled={isSubmitting || !isEdit}
												onChange={(value) => setFieldValue('endDate', value)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<TextField
												value={values.amount}
												disabled={isSubmitting || !isEdit}
												name="amount"
												type={values.isPercentage ? 'number' : 'text'}
												label={t('amount')}
												error={touched.amount && Boolean(errors.amount)}
												helperText={touched.amount && errors.amount}
												fullWidth
												// Check if which radio button is selected if its percentage
												// apply percentage mask and currency mask for euro
												onChange={(event) => setFieldValue('amount', values.isPercentage ? percentage(event).target.value : currency(event).target.value)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<RadioGroup
												row
												aria-labelledby="demo-row-radio-buttons-group-label"
												name="row-radio-buttons-group"
												value={values.isPercentage}
												onChange={(event) => {
													if (event.target.value === 'true') {
														setFieldValue('isPercentage', true);
														setFieldValue('amount', 0);
													} else if (event.target.value === 'false') {
														setFieldValue('isPercentage', false);
														setFieldValue('amount', '');
													}
												}}
											>
												<FormControlLabel disabled={isSubmitting || !isEdit} value="false" control={<Radio />} label="€" />
												<FormControlLabel disabled={isSubmitting || !isEdit} value="true" control={<Radio />} label="%" />
											</RadioGroup>
										</Grid>
										<Grid item xs={12} md={2}>
											<FormControlLabel
												checked={values.reusable}
												disabled={isSubmitting || !isEdit}
												control={<Checkbox name="reusable" />}
												onChange={handleChange}
												label={t('reusable').toString()}
											/>
										</Grid>

										<Grid item xs={12} md={2}>
											<FormControlLabel
												checked={values.automatedAudience}
												disabled={isSubmitting || !isEdit}
												control={<Checkbox name="automatedAudience" />}
												onChange={handleChange}
												label={t('automatedAudience').toString()}
											/>
										</Grid>

										<Grid item xs={12} md={2} />
										<Grid item xs={12} md={8}>
											<Description text={t('regulations')} />
											<RichTextEditor
												value={values.regulation}
												onChange={(value) => {
													setFieldValue('regulation', value);
												}}
												disabled={!isEdit}
												rootStyle={{
													backgroundColor: theme.palette.background.paper,
												}}
												editorStyle={{
													height: !isEdit ? 200 : 150,
												}}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<Description text={t('image')} />
											<Card>
												<div
													style={{
														backgroundPosition: 'center center',
														backgroundRepeat: 'no-repeat',
														backgroundImage: `url(${values.banner ? values.banner : logo})`,
														width: '100%',
														height: '200px',
													}}
												>
													<DefaultButton
														title={t('upload')}
														disabled={!isEdit || isSubmitting}
														onClick={() => banner.current?.click()}
														sx={{ mt: '5px', ml: '5px' }}
													/>
												</div>
											</Card>
										</Grid>
										<Grid item xs={12} md={1}>
											{isEdit && <DefaultButton title={t('save')} loading={isSubmitting} type="submit" />}
											{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} />}
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</Box>
	);
};

export default CreateCouponPage;
