import axios from 'axios';
import Endpoint from '../endpoints.api';
import { ApiResponse } from 'data/models';

const BASE_URL = process.env.REACT_APP_BASE_URL_RESET;

interface CheckResponse {
	id: number;
	name: string
}
const CONFIG_HEADERS = {
	'Access-Control-Allow-Origin': BASE_URL,
	Lang: 'pt',
	Accept: 'application/json',
};

export const checkResetToken = async (userId: string, token?: string):
	Promise<ApiResponse<CheckResponse>> => {
	const resp = await axios({
		method: 'POST',
		url: BASE_URL + Endpoint.CHECK_CHANGE_PASSWORD,
		data: { userId, token },
		headers: CONFIG_HEADERS,
	});
	const data = resp.data as ApiResponse<CheckResponse>;
	data.statusCode = resp.status;
	return data;
};

export const changePartnerPassword = async (
	userId: string | undefined,
	token: string | undefined,
	newPassword: string,
):
	Promise<ApiResponse<void>> => {
	const resp = await axios({
		method: 'POST',
		url: BASE_URL + Endpoint.CHANGE_PARTNER_PASSWORD,
		data: { userId, token, newPassword },
		headers: CONFIG_HEADERS,
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};
